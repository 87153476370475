<template>
<div class="tabulation" v-loading="loading" element-loading-text="加载中" element-loading-background="rgb(255, 255, 255, 0.5)">
  <el-card class="chart-card">
    <div style="margin-top: 20px;">
      <el-form
          ref="ruleFormRef"
          :model="form"
          :rules="rules"
          class="demo-ruleForm"
          :size="formSize"
          status-icon
       >
      <!-- 标题 -->
        <el-form-item label="标题名称 :" prop="name">
          <div>{{form.name}}</div>
        </el-form-item>
      <!-- 副标题 -->
        <el-form-item label="副标题 :" prop="bywork">
          <div>{{form.bywork  }}</div>
        </el-form-item>
      <!-- 简介 -->
        <el-form-item label="简介 :" prop="synopsis">
          <div>{{form.synopsis }}</div>
          <div v-show="form.synopsis==''||form.synopsis==null?true:false">无</div>
        </el-form-item>
      <!-- 标题图片 -->
        <el-form-item  label="缩略图 :" prop="breviary">
          <el-upload action="/admin/news/saveImage" list-type="picture-card" limit="1" 
            v-model="form.breviary"
            v-show="imgss==''||imgss==null?false:true"
            :auto-upload="true" 
            :on-change="change" 
            :on-remove="Remove"
            :file-list="approve"
            :class="styA==0&&styD==0?'styB':'styC'"
            :before-upload="beforeImageUpload"
            :on-preview="onpreview"
            :headers="header"
            accept=".jpeg,.png,.jpg,.bmp,.gif"
            disabled="true"
            :data='{
              imageFile: file
              }'
            name="imageFile"
            :on-success="succ"
            >
           <el-icon><Plus /></el-icon>
          </el-upload>
          <div v-show="imgss==''||imgss==null?true:false">无</div>

          <el-dialog v-model="dialogVisible">
            <img w-full :src="dialogImageUrl" alt="Preview Image" style="width:100%"/>
          </el-dialog>
        </el-form-item>
      <!-- 分类 -->
        <el-form-item label="动态分类 :" prop="sex">
          <div>
            {{form.sex}}
          </div>
        </el-form-item>
      <!-- 是否发布 -->
       <el-form-item label="是否发布 :" prop="are">
          <div>
            {{form.are}}
          </div>
        </el-form-item>
      <!-- 上传附件 -->
        <el-form-item label="附件 :">
            <el-upload
              :file-list="enclosure"
              class="upload-demo"
              action="/admin/news/saveFile"
              :auto-upload="true" 
              multiple
              :on-preview="handlePreview"
              :on-remove="onremove"
              :before-remove="cancel"
              :before-upload="paper"
              :limit="1"
              :on-exceed="handleExceed"
              :on-change="onchange"
              :headers="footer"
              name="file"
              :data='dese'
              :on-success="win"
              :show-file-list="whether"
              disabled="true"
              accept=".docx,.doc,.xlsx,.pdf"
            >
            <div v-show="pictures">无</div>
            </el-upload>
        </el-form-item>
      <!-- 编辑器 -->
        <div class="bianji">
          <div class="subject">
            <span>*</span>
            主体内容 :
          </div>
          <div v-html="valueHtml" class="cat"></div>
        </div>
    </el-form>
    </div>
  </el-card>
</div>
</template>
<script>
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { onBeforeUnmount, ref, shallowRef, onMounted,onActivated, reactive } from 'vue'
import {  Plus,} from '@element-plus/icons-vue'
import * as imageConversion from 'image-conversion'
import { ElMessage } from "element-plus";
import {
		useStore
	} from "vuex";
	import {
		useRouter,
		useRoute
	} from "vue-router";
// GHsubmit
import { GHredact, } from "../../utils/api"
import qs from 'qs'
export default{
  name:"DTdetails",
  components:{  Plus, },
  setup(){
	  const router = useRouter()
	  const route = useRoute()
    // 图片显示 隐藏
    let imgss=ref('')
    // 是否滚动
    let cat =ref('hidden')
    // 加载框
    let loading=ref(true)
    // 是否显示附件列表
    let whether= ref(false)
    // 默认显示已上传的附件
    let enclosure= ref([{}])
    // 默认显示已上传文件
    let approve =ref([])
    // 附件文件名称
    let enclosed = ref('')
    // 获取动态编辑信息
    let stand=()=>{
      let data={
        newId:sessionStorage.getItem('newsid')
      }
      GHredact(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          loading.value=false
          // 编辑器内容
          valueHtml.value=res.data.data.newInfo.content
          // 标题内容
          form.name=res.data.data.newInfo.title
          // 简介
          form.synopsis=res.data.data.newInfo.introduce
          // 副标题
          form.bywork=res.data.data.newInfo.subtitle
          // 图片
          imgss.value=res.data.data.newInfo.imageAddress
          if(res.data.data.newInfo.imageAddress==null||res.data.data.newInfo.imageAddress==''){
            styD.value=0
          }else{
            styD.value=1
            let data={
              url:res.data.data.newInfo.imageAddress
            }
            approve.value.push(data)
          }
          // approve.value[0].url=res.data.data.newInfo.imageAddress
          form.breviary=res.data.data.newInfo.imageAddress
          // 附件
          enclosure.value[0].url=res.data.data.newInfo.fileName
          // 附件名称
          enclosure.value[0].name=res.data.data.newInfo.fileOriginalName
          enclosed.value=res.data.data.newInfo.fileOriginalName
          // 是否显示附件列表
          if(res.data.data.newInfo.fileName==null||res.data.data.newInfo.fileName==''){
            whether.value=false
          }else{
            whether.value=true
          }
          // 是否显示附件按钮
          if(res.data.data.newInfo.fileName==null||res.data.data.newInfo.fileName==''){
            pictures.value=true
          }else{
            pictures.value=false
          }
          // 动态分类
          if(res.data.data.newInfo.type==1){
            form.sex='通知公告'
          }else if(res.data.data.newInfo.type==2){
            form.sex='最新资讯'
          }else if(res.data.data.newInfo.type==3){
            form.sex='规章制度'
          }
          // 是否发布
          if(res.data.data.newInfo.status==1){
            form.are='是'
          }else if(res.data.data.newInfo.status==2){
            form.are='否'
          }
        }
      })
    }



    let data = ref(null);
    // 按钮加载
    const ConfirmLoading=ref(false)
    // 上传附件
    // 获取上传文件地址
    let address=ref('')
    const win=(res)=>{
      address.value=res.data
      console.log(res)
    }
    const files=ref('')
    const dese=ref({
      file:files
    })
    // 上传文件请求头
    const footer=ref({
      timeout: 10000,
      'accessToken':sessionStorage.getItem('token')
    })
    // 上传文件限制文件大小
    const paper=(File)=>{
      files.value=File.name
      let isLt2Ms = File.size / 1024 / 1024 < 3?'1':'0'
      if(isLt2Ms=='0'){
        ElMessage({
          message: '文件大小不能超过3M',
          type: "error",
          center: true,
        });
      }
      return isLt2Ms==='1'?true: false;
    }
    // 上传附件
    const pictures = ref(true)
    // 删除附件
    const cancel=(File,fileList)=>{
      console.log(File,fileList.leng)
    }
    const onremove = (File,fileList)=>{
      console.log(File,fileList.length)
      if(fileList.length==0){
        pictures.value=true
      }
    }
    // 文件状态改变钩子
    const onchange=(File,fileList)=>{
      if(fileList.length>0){
        pictures.value=false
      }
    }
    // 点击上传文件钩子
    const handlePreview=(File)=>{
      console.log(File)
      window.open(File.url)
      // var a = document.createElement('a');
      // var event = new MouseEvent('click');
      // a.download = File.name;
      // a.href = File.url;
      // a.dispatchEvent(event);
    }

    
    // 上传图片
    const header=ref({
      timeout: 10000,
      'accessToken':sessionStorage.getItem('token')
    })
    // 获取上传图片成功地址
    let location= ref('')
    const succ=(res)=>{
      location.value=res.data
      console.log(res)
    }
    // 上传按钮是否显示
    const styA = ref(0)
    const styD=ref(1)
    
    const change=(res,fileList)=>{
      // console.log(res)
      styA.value=fileList.length
      form.breviary=fileList
      // console.log(fileList)
    }
    const beforeImageUpload=(File)=>{
      console.log(File)
      let isLt2M = File.size / 1024 / 1024 < 1 
      return new Promise((resolve)=>{
        if (isLt2M) {
          resolve(File)
        }
        imageConversion.compressAccurately(File, 930).then((res) => {
          resolve(res);
          // data.value = File.name
        });
      })
    }
    // 图片放大
    const dialogVisible=ref(false)
    let dialogImageUrl=ref('')
    const onpreview = (File)=>{
      dialogVisible.value=true
      dialogImageUrl.value=File.url
      console.log(File)
    }
    // 删除图片
    const Remove =(file)=>{
      styD.value=0
      styA.value=0
      console.log(file)
    }

 // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef()
 
    // 内容 HTML
    const valueHtml = ref('')
	let translate = ref(2)
    // 模拟 ajax 异步获取内容
    onActivated(()=>{
      translate.value = route.params.UserId
      if (translate.value == 1) {
      	loading.value = true
      	stand()
      }
    })
	// vuex获取状态
	const store = useStore();
	// 获取当前页path
	const present = ref('')
	// 通过vuex获取标签栏
	const tally = ref([])
    onMounted(() => {
	
		
      stand()
    })
    // 富文本上传图片地址
    // let fils=ref('')
    // placeholder: '请输入内容...',
    const editorConfig = { MENU_CONF: {} }
    editorConfig.MENU_CONF['uploadImage'] = {
    // 上传图片的配置 
    // 接口
      server:'/admin/news/saveEditorImage',
    // 请求头
      headers: {
        timeout: 10000,
        'accessToken':sessionStorage.getItem('token')
      },
      maxFileSize: 1 * 1024 * 1024, // 1M
    //上传字段 
      fieldName: 'imageFile',
      // meta: {
      //   imageFile:fils
      // },
      // 最多可上传几个文件，默认为 100
      maxNumberOfFiles: 1,
      // 上传前回调
      onBeforeUpload(file) {
        console.log(file)
        let fileObj = Object.values(file)[0].data
        console.log(fileObj)
        // 压缩图片
        let isLt2M = Object.values(file)[0].data.size
        if(isLt2M<1048576){
          return true
        }else if(isLt2M>1048576){
          ElMessage({
            message: '图片大小不能超过1MB,请重新上传',
            type: "warning",
            center: true,
          });
          return false
        }
      },
      // 上传成功后回调
      onSuccess(file, res) {
        console.log(file)
        console.log(res)
      },
      // 上传失败回调
      onError() {
        ElMessage({
            message: '图片大小不能超过1MB,请重新上传',
            type: "warning",
            center: true,
          });
    },
    }
 
    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
      const editor = editorRef.value 
      if (editor == null) return
      editor.destroy()
    })
 
    const handleCreated = (editor) => {
      editorRef.value = editor // 记录 editor 实例，重要！
      // console.log(editor)
    }
    // 标题验证
    const ruleFormRef = ref(null)
    const form = reactive({
      name:"",
      sex:"",
      breviary:"",
      copyreader:"",
      are:'',
      bywork:'',
      synopsis:''
    })
    // 验证表单提示
    const rules ={
      bywork:[
        { required: true, message: "请填写副标题", trigger: "change" },
      ],
      are:[
        { required: true, message: "请选择是否发布", trigger: "change" },
      ],
      copyreader:[
        { required: true, message: "请填写内容", trigger: "change" },
      ],
      // breviary:[
      //   { required: true, message: "请上传缩略图", trigger: "change" },
      // ],
      name:[
        { required: true, message: "请填写标题", trigger: "change" },
      ],
      sex:[
        { required: true, message: "请选择分类", trigger: "change" },
      ],
    }
    return{
      imgss,
      // 屏幕是否滚动
      cat,
      // 加载框
      loading,
      // 是否显示附件列表
      whether,
      // 默认显示上传附件
      enclosure,
      // 默认已上传图片
      approve,
      // 请求头
      header,
      succ,
      data,
      // 按钮加载
      ConfirmLoading,
      // 上传附件
      win,
      dese,
      footer,
      paper,
      onremove,
      cancel,
      onchange,
      pictures,
      handlePreview,
      // 上传图片
      dialogVisible,
      dialogImageUrl,
      onpreview,
      Remove,
      styD,
      styA,
      change,
      beforeImageUpload,
      // handlePictureCardPreview,
      // 编辑器
      editorRef,
      valueHtml,
      mode: 'default',
      editorConfig,
      handleCreated,
      // 标题验证
      ruleFormRef,
      form,
      rules
    }
  }
 }
</script>
<style lang='scss' scoped>
::v-deep .chart-card::-webkit-scrollbar{
  display:none
}
.chart-card{
  height: calc(100vh - 150px);
  overflow: auto;
}
::v-deep .bianji{
  display: flex;
  flex-direction: column;
}
::v-deep .cat{
  width: 97%;
  // margin-left: 100px;
  border: 1px solid #DCDFE6;
  padding: 10px;
  margin-left: 10px;
}
::v-deep .subject{
  width: 85px;
  margin-left: 12px;
  margin-bottom: 10px;  
  font-size: var(--el-form-label-font-size);
  font-weight: bold;
  color: var(--el-text-color-regular);
  span{
    color: var(--el-color-danger);
  }
}
::v-deep .el-button{
  width: 100px;
  height: 40px;
  font-size: 16px;
}
::v-deep .el-form-item__content{
  align-items: flex-end;
}
::v-deep .referrals{
  color: #cccccc;
  margin-left: 5px;
}
::v-deep .styB .el-upload--picture-card{
  width:110px;
  height:110px;
  line-height:110px;
}
::v-deep .styC .el-upload--picture-card{
  display:none;   
}
::v-deep .el-form-item{
  align-items: center;
}
.aaaa{
  width: 50%;
}
::v-deep .el-card__body {
  padding: 0;
}
::v-deep .el-main{
  overflow: auto !important;
}
.el-card.is-always-shadow{
  width: 95%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.tabulation {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  // overflow: auto !important;
}
</style>
<style scoped>
::v-deep .upload-demo{
  display: flex;
  width: 100%;
}
::v-deep .upload-demo .el-upload-list{
  display: flex;
  width: 50%;
}
::v-deep .el-input{
  height: 44px !important;
}
::v-deep .el-form-item__label{
  width: 100px !important;
  font-weight: bold;
}
</style>
